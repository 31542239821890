import { FeatureToggleClient, FeatureToggleIdentity, FeatureToggles } from '@catalystone/feature-toggles-client-js';
import { environmentService } from '../environment.service';
import { stateService } from '../../application/state/state.service';

class FeatureTogglesService {
  private clients: Map<string, FeatureToggleClient> = new Map();

  initialize(): void {
    FeatureToggles.init(`${environmentService.env.apiUrl}feature-toggles`);
  }

  async getClient(clientName: string): Promise<FeatureToggleClient> {
    if (!this.clients.has(clientName)) {
      const client = await FeatureToggles.getClient(clientName);
      this.clients.set(clientName, client);
    }

    return this.clients.get(clientName)!;
  }

  private get tenantName(): string {
    const hostName = window.location.hostname.split('.catalystone')[0];
    return hostName.split('.')[0];
  }

  private get tenantId(): string {
    return stateService?.getSnapshot()?.auth?.token.getTenantId();
  }

  async isFeatureEnabled(clientName: string, featureName: string): Promise<boolean> {
    const client = await this.getClient(clientName);
    return client.isFeatureEnabled(
      featureName,
      new FeatureToggleIdentity(undefined, undefined, undefined, this.tenantId, this.tenantName)
    );
  }
}

export const featureTogglesService = new FeatureTogglesService();
