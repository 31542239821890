import * as SingleSpa from 'single-spa';
import { fulfillsAnyPredicate } from '../utilities/activity-functions';
import { featureTogglesService } from './feature-toggles.service';
import { environmentService } from '../environment.service';
import { stateService } from '../../application';

class RoutingService {
  constructor() {}

  bindEvents() {
    window.addEventListener('single-spa:routing-event', (event) => this.decideIfApplicationExists(event), false);
    window.addEventListener('single-spa:before-first-mount', this.firstRedirect, false);
  }

  private async decideIfApplicationExists(event: Event): Promise<void> {
    const loc = (event.target as Window).location;

    if (fulfillsAnyPredicate(loc)) {
      return;
    }

    await this.checkAndNavigateToOneToOneDashboard(loc);
  }

  private firstRedirect(event: Event): void {}

  private async checkAndNavigateToOneToOneDashboard(loc: Location): Promise<void> {
    if (loc.href.includes('one-to-one-app')) {
      const isFeatureEnabled = await featureTogglesService.isFeatureEnabled('one-to-one', 'angularreactSupport');

      if (isFeatureEnabled) {
        const updatedUrl = loc.href.replace('one-to-one-app', 'one-to-one-angular');
        SingleSpa.navigateToUrl(updatedUrl);
      }
    }
  }
}

export const routingService = new RoutingService();
