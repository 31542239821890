import * as amplitude from '@amplitude/analytics-browser';
import { environmentService } from '../environment.service';

type Amplitude = typeof amplitude;
export type AmplitudeReturn = ReturnType<typeof amplitude.init>;

declare global {
  interface Window {
    amplitude: Amplitude;
    amplitudeDev: Amplitude;
  }
}

export interface UserAnalyticsMetadata {
  [key: string]: string | number | boolean | object;
}

export interface UserAnalyticsIdentity {
  visitor?: UserAnalyticsMetadata;
  account?: UserAnalyticsMetadata;
}

class UserAnalyticsService {
  initialize = async () => {
    const amplitudePromise = this.initializeAmplitude();
    (window as any)['amplitude'] = amplitude;

    return Promise.allSettled([amplitudePromise]);
  };

  private async initializeAmplitude(): Promise<AmplitudeReturn> {
    return amplitude.init(environmentService.env.amplitudeKey, {
      identityStorage: 'localStorage',
      autocapture: true,
      fetchRemoteConfig: true,
      serverZone: 'EU'
    });
  }
}

export const userAnalyticsService = new UserAnalyticsService();
