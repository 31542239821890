export class DirtyPage {
  private isDirty = false;

  setIsDirty(value: boolean) {
    this.isDirty = value;
  }

  IsDirty() {
    return this.isDirty;
  }
}
