import { serviceDownComponent, telemetryService } from '../application';
import { configLoader, featureTogglesService } from './services';
import { Environment } from '@catalystone/react-core/spa-types';

function getWindow(): any {
  return window;
}

class EnvironmentService {
  env: Environment;

  constructor() {}

  init = async (): Promise<Environment> => {
    return configLoader
      .loadJSON<Environment>('../assets/environment.json')
      .then((variables: Environment) => {
        if (!variables) return Promise.reject(new Error('incoming variables object is nullish'));

        this.env = { ...variables };
        getWindow()['__env'] = this.env;
        return Promise.resolve(this.env);
      })
      .catch((error) => {
        telemetryService.logException(new Error(`error loading environment.json file: ${error}`));
        serviceDownComponent.show();
        throw error;
      });
  };

  updateEnv = (env: Partial<Environment>) => {
    const newEnv = { ...this.env, ...env };
    this.env = newEnv;
    getWindow()['__env'] = newEnv;
  };
}

export const environmentService = new EnvironmentService();
